import React, {useState} from 'react';
import {
    CardCvcElement, CardElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";

const CheckoutForm = ({ routes, initialPrice, intentId }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [cardErrors, setCardErrors] = useState(null);
    const [expErrors, setExpErrors] = useState(null);
    const [cveErrors, setCVCErrors] = useState(null);
    const [globalError, setGlobalError] = useState(null);
    const [promoCode, setPromoCode] = useState("");
    const [skipPayment, setSkipPayment] = useState(false);
    const [promoCodeSuccess, setPromoCodeSuccess] = useState(false);
    const [promoError, setPromoError] = useState(null);
    const [price, setPrice] = useState(initialPrice);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const obitForm = document.getElementById('edit_legacy');

        if (skipPayment) {
            const promoCodeField = document.getElementById('legacy_promo_code');
            promoCodeField.value = promoCode;
            obitForm.submit();
            return;
        }

        if (!stripe || !elements) {
            return;
        }

        const result = await stripe.confirmCardPayment(window.clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name: window.user.name,
                    email: window.user.email,
                },
            }
        });

        if (result.error) {
            setGlobalError(result.error.message);
        } else {
            if (result.paymentIntent.status === 'succeeded') {
                const responseField = document.getElementById('legacy_payment_response');
                responseField.value = JSON.stringify(result);
                obitForm.submit();
            }
        }

    };

    const applyPromoCode = async (e) => {
        e.preventDefault();
        const csrfToken = document.querySelector("[name='csrf-token']").content;

        if (promoCode && promoCode.trim()) {
            const response = await fetch(routes.updatePaymentIntent, {
                method: 'PATCH',
                headers: {
                    "X-CSRF-Token": csrfToken,
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  promo_code: promoCode,
                  intent_id: intentId
                }),
                credentials: 'same-origin'
            });
            if (response.status === 200) {
                const json = await response.json();
                setGlobalError(null);
                setSkipPayment(json.payment_required == false);
                setPromoCodeSuccess(true);
                setPrice(json.price);
                setPromoError(null);
            } else {
                setSkipPayment(false);
                setPromoError((await response.json()).error);
            }
        } else {
            setPromoError("You must enter a promo code");
            setSkipPayment(false);
        }
    }

    return (
        <>
            <div className="row mb-4">
                <div className="col">
                    <strong>{price}</strong>
                </div>
            </div>

            <form onSubmit={applyPromoCode}>
                <div className="form-row mb-1">
                    <div className="col-6">
                        <label>Have a promo code?</label>
                        <input
                            type={"text"}
                            value={promoCode}
                            onChange={e => {
                                setPromoCode(e.target.value);
                                setSkipPayment(false);
                                setPromoCodeSuccess(false);
                            }}
                            placeholder={"Enter promo code"}
                            className={`form-control ${promoCodeSuccess ? "is-valid" : ""}`}
                        />
                        {
                            promoCodeSuccess && (
                                <div className={'valid-feedback d-block'}>
                                    Promo code applied successfully!
                                </div>
                            )
                        }
                        {
                            promoError
                                ?
                                <div className={'invalid-feedback d-block'}>{promoError}</div>
                                :
                                ''
                        }
                    </div>
                </div>
                {
                    !skipPayment && (
                        <div className="form-row mb-5">
                            <div className="col-6">
                                <button type={"submit"}
                                        className={"btn btn-primary btn-small"}>
                                    Apply Promo Code
                                </button>
                            </div>
                        </div>
                    )
                }
            </form>

            {
                globalError
                    ?
                    <div className="alert alert-danger">
                        {globalError}
                    </div>
                    :
                    ''
            }

            <form onSubmit={handleSubmit}>
                {
                    !skipPayment && (
                        <>
                            <div className="form-row">
                                <div className="col-6">
                                    <label>Card Number</label>
                                    <CardNumberElement
                                        onChange={e => setCardErrors(e.error ? e.error.message : null)}
                                        disabled={skipPayment}
                                        className="form-control"
                                        options={{
                                            style: {
                                                base: {
                                                    paddingTop: '3px',
                                                    color: "#32325d",
                                                }
                                            }
                                        }}
                                    />
                                    {
                                        cardErrors
                                            ?
                                            <div className={'invalid-feedback d-block'}>{cardErrors}</div>
                                            :
                                            ''
                                    }
                                </div>
                            </div>
                            <div className="form-row mt-2">
                                <div className="col-3">
                                    <label>Expiration</label>
                                    <CardExpiryElement className="form-control"
                                                       onChange={e => setExpErrors(e.error ? e.error.message : null)}
                                    />
                                    {
                                        expErrors
                                            ?
                                            <div className={'invalid-feedback d-block'}>{expErrors}</div>
                                            :
                                            ''
                                    }
                                </div>
                                <div className="col-3">
                                    <label>CVC</label>
                                    <CardCvcElement className="form-control"
                                                    onChange={e => setCVCErrors(e.error ? e.error.message : null)}
                                    />
                                    {
                                        cveErrors
                                            ?
                                            <div className={'invalid-feedback d-block'}>{cveErrors}</div>
                                            :
                                            ''
                                    }
                                </div>
                            </div>
                        </>
                    )
                }

                <div className="save-bar box-shadow w-100 fixed-bottom-responsive p-3 border-top">
                    <div className="row">
                        <div className="col-sm-12 col-lg-6 text-center text-lg-left">
                            &nbsp;
                        </div>
                        <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                            <input type="submit"
                                   name="commit"
                                   value={skipPayment ? "Continue" : "Pay"}
                                   className="mr-0 mr-lg-2 float-right btn btn-lg btn-primary pl-3 pr-3 font-size-18 font-weight-bold w-100 w-lg-auto mb-2 mb-lg-0"
                                   disabled={!stripe}/>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default CheckoutForm;

import { StreamActions } from "@hotwired/turbo"

StreamActions.redirect = function() {
    const url = this.getAttribute("url")
    const delay = parseInt(this.getAttribute("delay") || 0)
    console.log("action/redirect", url, delay)
    setTimeout(() => {
        Turbo.visit(url, { frame: "_top", action: "replace" })
    }, delay)
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.element.focus()
    let end = this.element.value.length
    this.element.setSelectionRange(end, end)
  }
}


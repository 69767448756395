import React, {useCallback, useState} from "react";
import fileUploadIcon from "../../images/icons/file-upload.svg";
import {useDropzone} from "react-dropzone";

function AdditionalPhotos({serverPhotos, letter}) {
    const [photos, setPhotos] = useState(serverPhotos);
    let metaTag = document.querySelector('[name=csrf-token]');
    const csrfToken = metaTag ? metaTag.content : "";
    const [error, setError] = useState(false);

    const onDrop = useCallback(async acceptedFiles => {
        if (!acceptedFiles.length) {
            setError(true)
            return
        };
        const files = acceptedFiles;
        const formData = new FormData();
        files.forEach(file => {
            console.log(file);
            formData.append('letter[photos][]', file);
        });

        const response = await fetch(letter.uploadPhotosPath, {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRF-Token': csrfToken
            },
        });
        const json = await response.json();
        setPhotos(photos => json);
    }, []);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg']
        }
    });

    return (
        <>
            <div className="row">
                <div className="col">
                    {
                        photos.length ? (
                            <div className="row">
                                <div className="col-12">
                                    {
                                        photos.map((photo, i) => {
                                            return (
                                                <div className="photo-wrapper position-relative d-inline-block"
                                                     style={{
                                                         width: '240px',
                                                         "fontSize": "40px",
                                                         "marginRight": "40px",
                                                         "marginBottom": "40px"
                                                     }}
                                                     key={i}>
                                                    <button type="button"
                                                            className="position-absolute close rounded-circle bg-light border-gray"
                                                            aria-label="Close"
                                                            style={{
                                                                top: "-20px",
                                                                right: "-20px",
                                                                cursor: "pointer",
                                                                width: "40px",
                                                                height: "40px",
                                                                opacity: "1"
                                                            }}
                                                            onClick={async () => {
                                                                const response = await fetch(letter.deletePhotosPath, {
                                                                    method: 'DELETE',
                                                                    body: JSON.stringify({photo_id: photo.id}),
                                                                    headers: {
                                                                        'Content-Type': 'application/json',
                                                                        'Accept': 'application/json',
                                                                        'X-CSRF-Token': csrfToken
                                                                    },
                                                                });
                                                                const json = await response.json();
                                                                setPhotos(photos => json);
                                                            }}>
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                    <img src={photo.url} alt="additional photo"/>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        ) : null
                    }
                </div>
            </div>
            <div className={`row ${photos.length ? 'small-box' : 'large-box'}`}>
                <div className="col">
                    <div
                        className={`text-center p-5 border rounded border-dashed mt-4 ${isDragActive ? "bg-secondary" : ""}`} {...getRootProps()}>
                        <div className="mb-4">
                            <img src={fileUploadIcon} alt="Upload Photo"/>
                        </div>
                        <div className="mb-4">
                            <h1>Upload Additional Photos</h1>
                        </div>
                        <div>
                            <input {...getInputProps()} />

                            <label className="btn btn-primary">
                                Browse
                            </label>
                            <div>
                                <div className={`alert alert-${error ? 'danger' : 'warning'} d-inline-block`}>NOTE: we only accept JPG, GIF and PNG files</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default function LetterPhotoUploader({photo, letter, photos}) {
    console.log(photo)
    const [photoUrl, setPhotoUrl] = useState(photo.url);

    let metaTag = document.querySelector('[name=csrf-token]');
    const csrfToken = metaTag ? metaTag.content : "";
    const [error, setError] = useState(false);

    const onDrop = useCallback(async acceptedFiles => {
        if (!acceptedFiles.length) {
            setError(true)
            return
        };
        const files = acceptedFiles;
        const formData = new FormData();
        formData.append('letter[photo]', files[0]);

        const response = await fetch(letter.uploadPhotoPath, {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRF-Token': csrfToken
            },
        });
        const json = await response.json();
        setPhotoUrl(json.photoUrl);
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg']
        }
    });

    if (photoUrl) {
        return (
            <>
                <div className={`mb-5 border rounded p-4 mt-4 ${photoUrl ? 'd-block' : 'd-none'}`}>
                    <div className="row">
                        <div className="col">
                            {
                                photoUrl ?
                                    <img src={photoUrl} alt="letter image"/> :
                                    ""
                            }
                        </div>
                        <div className="col text-right">
                            <button className="btn btn-default"
                                    type={"button"}
                                    onClick={async e => {
                                        e.preventDefault();
                                        const response = await fetch(letter.deletePhotoPath, {
                                            method: 'DELETE',
                                            headers: {
                                                'X-CSRF-Token': csrfToken
                                            },
                                        });
                                        setPhotoUrl(null);
                                    }}
                            ><i className="far fa-trash-alt"/>
                            </button>
                        </div>
                    </div>
                </div>
                <AdditionalPhotos serverPhotos={photos} letter={letter}/>
            </>
        );
    } else {
        return (
            <>
                <div className={`mb-5 row ${photoUrl ? 'd-block' : 'd-block'}`}>
                    <div className="col">
                        <div className="text-center p-5 border rounded border-dashed mt-4" {...getRootProps()}>
                            <div className="mb-4">
                                <img src={fileUploadIcon} alt="Upload Photo"/>
                            </div>
                            <div className="mb-4">
                                <h1>Upload Primary Photo</h1>
                            </div>
                            <div>
                                {
                                    isDragActive ?
                                        <p>Drop the files here ...</p> :
                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                }
                            </div>
                            <div>
                                <input {...getInputProps()} multiple={false}/>

                                <label className="btn btn-primary">
                                    Browse
                                </label>
                                <div>
                                    <div className={`alert alert-${error ? 'danger' : 'warning'} d-inline-block`}>NOTE: we only accept JPG, GIF and PNG files</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AdditionalPhotos serverPhotos={photos} letter={letter}/>
            </>
        );
    }
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import CheckoutForm from "./components/checkout/CheckoutForm";

// document.documentElement.addEventListener('turbo:load', () => {
//     console.log('turbo:load payment.jsx');
//     let el = document.getElementById('payment-form')
//     if (el) {
//         window.initPaymentJS(el)
//     }
// });

export function initPaymentJS(el) {
    const stripePromise = loadStripe(window.publishableKey);
    let root = ReactDOM.createRoot(el);
    root.render(
        <Elements stripe={stripePromise}>
            <CheckoutForm routes={window.routes} initialPrice={window.price} intentId={window.intentId}/>
        </Elements>
    );
}

import { Controller } from "@hotwired/stimulus";
import SignaturePad from "signature_pad";

export default class extends Controller {
  static targets = ["form", "signatureCanvas"];

  connect() {
    super.connect();
    this.signaturePad = new SignaturePad(this.signatureCanvasTarget, {drawOnly: true});
  }

  clear() {
    event.preventDefault();
    this.signaturePad.clear();
  }

  serializeSignature() {
    return this.signaturePad.toDataURL();
  }

  resizeCanvas() {
    // Adjust the canvas size to match its container
    this.canvas.width = this.canvas.offsetWidth;
    this.canvas.height = this.canvas.offsetHeight;
  }

  // Call resizeCanvas whenever the window is resized
  windowResizeHandler() {
    this.resizeCanvas();
  }

  submitForm(event) {
    event.preventDefault();

    if (this.signaturePad.isEmpty()) {
      const submitButton = document.querySelector('#terms-submit-button'); // Replace with your submit button's ID
      submitButton.disabled = false;
      return;
    }

    const formData = new FormData(this.formTarget);
    const signatureDataUrl = this.serializeSignature();

    formData.append("terms_signature", signatureDataUrl);

    // Programmatically submit the form
    fetch(this.formTarget.action, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((result) => {
        const event = new CustomEvent("close-modal");
        window.dispatchEvent(event);
        // Handle successful form submission
      })
      .catch((error) => {
        // Handle errors
      });
  }

  updateSubmitButtonState() {
    const submitButton = document.querySelector('#terms-submit-button'); // Replace with your submit button's ID
    submitButton.disabled = this.signaturePad.isEmpty();
  }
}

// Ensure to add the windowResizeHandler to the window's resize event listener
window.addEventListener('resize', () => {
  document.querySelector('[data-controller="signature-pad"]').windowResizeHandler();
});



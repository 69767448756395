import React from 'react';
import ReactDOM from 'react-dom/client';
import LetterPhotoUploader from "./components/uploader/LetterPhotoUploader";

// document.addEventListener('DOMContentLoaded', () => {
//     let root = ReactDOM.createRoot(document.getElementById('uploader'));
//     root.render(
//         <LetterPhotoUploader letter={window.letter} photo={window.photo || {}} photos={window.photos}/>
//     );
// });
// document.documentElement.addEventListener('turbo:render', () => {
//     console.log('turbo:render letter-photo-uploader.js');
//     let el = document.getElementById('uploader')
//     if (el) {
//         let root = ReactDOM.createRoot(el);
//         root.render(
//             <LetterPhotoUploader letter={window.letter} photo={window.photo || {}} photos={window.photos}/>
//         );
//     }
// });
export function initLetterPhotosJS(el) {
    let root = ReactDOM.createRoot(el);
    root.render(
        <LetterPhotoUploader letter={window.letter} photo={window.photo || {}} photos={window.photos}/>
    );
}

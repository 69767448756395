import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: String,
    turboStream: Boolean
  }

  connect () {
    const headers = {}
    if (this.turboStreamValue) {
      headers["Accept"] = "text/vnd.turbo-stream.html, text/html, application/xhtml+xml"
    }
    fetch(this.urlValue, {
      headers: headers
    })
    .then((resp) => resp.text())
    .then((body) => {
        if (this.turboStreamValue)
          Turbo.renderStreamMessage(body)
        else 
          this.element.innerHTML = body
    })
  }
}

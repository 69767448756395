import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js'

/*
 * <div
 *   data-controller="tip"
 *   data-tip-text="Click to edit">Copy to Clipboard</button>
 */
export default class extends Controller {
  static values = {
    text: String
  }

  connect () {
    this.tip = window.tippy(this.element, {
      content: this.textValue,
      placement: 'bottom-start',
      theme: 'dark',
    });

    // console.log('tip_controller connected', this.element, this.tip, this.textValue)
  }

  disconnect () {
    if (this.tip)
      this.tip.destroy()
  }
}

// import 'bootstrap';
import "./src/application.scss";
import "@fortawesome/fontawesome-free/js/all";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import "trix/dist/trix.css"
import "./src/actiontext.scss";
import { initPaymentJS } from "./payment";
import { initLetterPhotosJS } from "./letter-photo-uploader";

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("trix")
require("@rails/actiontext")

import * as Turbo from "@hotwired/turbo"
import { Application } from "@hotwired/stimulus"
import TipController from "./controllers/tip_controller"
import ClipboardController from "./controllers/clipboard_controller"
import FlashController from "./controllers/flash_controller"
import FocusController from "./controllers/focus_controller"
import ModalController from "./controllers/modal_controller"
import FetchController from "./controllers/fetch_controller"
import SignaturePadController from "./controllers/signature_pad_controller"

import 'actions/redirect';

import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/index';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/util';
import "chartkick/chart.js"

import $ from 'jquery';
import ahoy from "ahoy.js"
window.ahoy = ahoy

import tippy from 'tippy.js'
window.tippy = tippy

// Moved to stimulus controller
// $(function () {
//     const toastElement = $('#flash');
//     if (!toastElement.length) return;
//     toastElement
//         .toast({delay: 5000})
//         .toast('show')
//         .on('click', _ => toastElement.toast('hide'));
// });

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('./images', true);
const imagePath = (name) => images(name, true);

window.Stimulus = Application.start()
Stimulus.register("flash", FlashController)
Stimulus.register("tip", TipController)
Stimulus.register("clipboard", ClipboardController)
Stimulus.register("focus", FocusController)
Stimulus.register("modal", ModalController)
Stimulus.register("fetch", FetchController)
Stimulus.register("signature-pad", SignaturePadController)

document.documentElement.addEventListener('turbo:load', () => {
    let el = document.getElementById('uploader')
    if (el) {
        initLetterPhotosJS(el)
    }

    el = document.getElementById('payment-form')
    if (el) {
        initPaymentJS(el)
    }
});
